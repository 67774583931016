<template>
  <div class="main">
    <el-scrollbar>
      <transition :name="transitionName">
        <router-view />
      </transition>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'homeMain',
  data() {
    return {
      transitionName: '',
    }
  },
  watch: {
    // 使用watch 监听$router的变化
    $route(to, from) {
      // 如果to索引大于from索引,判断为前进状态,反之则为后退状态
      console.log(to, 'to')
      console.log(from, 'from')
      if (to.meta.index > from.meta.index) {
        // 设置动画名称
        this.transitionName = 'slide-left'
      } else {
        this.transitionName = 'slide-right'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-width: 1000px;
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: left 0.3s ease;
  overflow: hidden;
  // background-color: #dbd6d0;
  // background-color: #24292f;
  background-color: #f2f3f2;
  // color: #000;
}
.el-scrollbar {
  height: 100%;
}

.el-scrollbar ::v-deep.el-scrollbar__wrap {
  overflow: scroll;
  height: 100% !important;
  overflow-x: hidden !important;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
