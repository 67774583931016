import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import logo from '../assets/image/logo.png'
import logo from '../assets/image/gzmjc2.png'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    unitId: null,
    userId: null,
    logo: null,
    power: null,
    token: null,
    name: null,
    password: null,
  },

  mutations: {
    updateUserInfo(state, val) {
      console.log('wwwwerwerewfrwefwef',val)
      state.unitId = val.fkCompanyId
      state.userId = val.pkUserId

      state.power = val.fkJurisdictionId

      // state.logo = val.company ? val.company.logo : ''
      
      if (val.fkJurisdictionId === 4) {
        console.log(logo)
        state.logo = logo
      }
      if (!val.company || !val.company.logo) {
        state.logo = logo
      }

      state.name = val.name
      state.password = val.password
      state.isLogin = true
      state.token = val.token
    },
    uploadLogo(state, val) {
      state.logo = val
    },
    delUserInfo(state) {
      state.unitId = null
      state.userId = null
      state.logo = null
      state.power = null
      state.name = null
      state.password = null
      state.isLogin = null
      state.token = null
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
})
