import service from '../utils/request'

// 上传文件
export function uploadFile(id, type, file) {
  return service({
    method: 'put',
    url: '/task/updateFile',
    params: {
      id,
      type,
    },
    headers: {
      // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryVBBbWBGm8vvpZRyl',
      'Content-Type': 'multipart/form-data',
      // 'Content-Type':"application/json;charset=UTF-8"
    },
    data: file,
  })
}

// 公司logo上传
export function uploadLogo(id, formData) {
  return service({
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `/company/uploadLogo`,
    params: {
      id,
    },
    data: formData,
  })
}
