<template>
  <div class="homeAPP">
    <Header></Header>
    <TagNave></TagNave>
    <Main> </Main>
  </div>
</template>

<script>
import Header from './header.vue'
import TagNave from './tagNave.vue'
import Main from './main.vue'

export default {
  name: 'home',
  components: {
    Main,
    Header,
    TagNave,
  },
  data() {
    return {}
  },
  methods: {},
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.homeAPP {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
</style>
