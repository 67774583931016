import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Echart from 'vue-echarts'
import dataV from '@jiaminghi/data-view'

import * as echarts from 'echarts'

import '@/assets/css/reset.css'
import router from './router'
import store from './store'

// import pako from 'pako' // pako解压工具

// Vue.prototype.$zip = function (str) {
//   var binaryString = pako.gzip(encodeURIComponent(str), { to: 'string' })
//   return btoa(binaryString)
// }

// Vue.prototype.$unzip = function (b64Data) {
//   var strData = atob(b64Data)
//   // 将二进制字符串转换为字符数数组
//   var charData = strData.split('').map(function (x) {
//     return x.charCodeAt(0)
//   })
//   // 把数字数组转换成字节数组
//   var binData = new Uint8Array(charData)
//   // 解压
//   var data = pako.inflate(binData)
//   //将解压缩完成的的byteArray转换回ascii字符串:
//   strData = new TextDecoder('utf-8').decode(data) //大数据使用此方法
//   //var strData   = String.fromCharCode.apply(null, new Uint16Array(data));//数据不是很多可以使用此方法
//   return decodeURIComponent(strData)
// }

Vue.prototype.$echarts = echarts
Vue.component('v-chart', Echart)
Vue.config.productionTip = false

Vue.use(dataV)
Vue.use(ElementUI)

Vue.directive('permission', {
  inserted(el, binding) {
    if (binding.expression > store.state.power) {
      console.log('您无此权限')
      el.remove()
    }
  },
})

Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
