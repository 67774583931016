<template>
  <div class="projectInfo">
    <div class="header">
      <span class="fl projectName"> {{ title }} </span>
      <el-menu :default-active="this.$route.path" router class="fr el-menu-demo" mode="horizontal" background-color="#004C6E" text-color="#fff" active-text-color="#0DBC79">
        <el-menu-item :index="index.Condition">监测情况</el-menu-item>
        <el-menu-item :index="index.Data">监测数据</el-menu-item>
        <el-menu-item :index="index.Chart">监测曲线</el-menu-item>
        <el-menu-item :index="index.Alarm">警情信息</el-menu-item>
          <el-menu-item :index="index.Video">视频预览</el-menu-item>
        <el-menu-item :index="index.PointConfig">测点配置信息</el-menu-item>
        <el-menu-item :index="index.Info">工程介绍</el-menu-item>
      </el-menu>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { findTaskByTaskId } from '@/api/Task.js'
export default {
  data() {
    return {
      index: {
        Condition: '/ProjectMonitor/' + this.$route.params.projectId + '/Condition',
        Data: '/ProjectMonitor/' + this.$route.params.projectId + '/Data',
        Chart: '/ProjectMonitor/' + this.$route.params.projectId + '/Chart',
        Alarm: '/ProjectMonitor/' + this.$route.params.projectId + '/Alarm',
        Video: '/ProjectMonitor/' + this.$route.params.projectId + '/Video',
        PointConfig: '/ProjectMonitor/' + this.$route.params.projectId + '/PointConfig',
        Info: '/ProjectMonitor/' + this.$route.params.projectId + '/Info',
      },
      title: null,
    }
  },
  methods: {
    async findTaskByTaskIdFun() {
      const { data: res } = await findTaskByTaskId(this.$route.params.projectId)

      this.title = res.data.list[0].projectName
    },
  },
  created() {
    this.findTaskByTaskIdFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.projectInfo {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;

  height: 61px;
  border: none;
  background-color: #004c6e;
  .projectName {
    line-height: 61px;
    height: 100%;
    color: #fff;
    padding-left: 30px;
    font-size: 20px;
    font-weight: bold;
  }
}
.main {
  width: 100%;
  height: calc(100% - 61px);
}
</style>
