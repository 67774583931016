export function nthIndexOf(str, chara, num = 1) {
  let count = 0
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === chara) {
      count += 1
    }

    if (count === num) {
      return i
    }
  }

  return -1
}
