import service from '../utils/request'

export function login(userInfo) {
  return service({
    method: 'post',
    url: '/user/login',
    data: userInfo,
  })
}
export function captcha() {
  return service({
    method: 'get',
    url: '/user/captcha',
  })
}

// userName name password phone fkCompanyId fkJurisdictionId
export function register(userInfo) {
  return service({
    method: 'post',
    url: '/user/register',
    params: userInfo,
  })
}
//  userName name password phone pkUserId
export function updateUser(userInfo) {
  return service({
    method: 'put',
    url: '/employee/updateUser',
    data: userInfo,
  })
}

// 查看单位下的用户
export function findUserListByUnit(id, page, pageSize) {
  return service({
    method: 'get',
    url: '/employee/findEmployee',
    params: {
      id,
      page,
      pageSize,
    },
  })
}
// 查询所有 用户
export function findAllUser(page, pageSize) {
  return service({
    method: 'get',
    url: '/employee/findAll',
    params: {
      page,
      pageSize,
    },
  })
}

export function findUserById(id) {
  return service({
    method: 'get',
    url: '/employee/findById',
    params: {
      id,
    },
  })
}

// 删除用户
export function deleteUser(id) {
  return service({
    method: 'delete',
    url: '/employee/removeUser',
    params: {
      id,
    },
  })
}

// 查看用户的单位情况信息
// 用户单位的 主机 和节点数量 统计
export function findHostAndNodeSum(userId) {
  return service({
    method: 'get',
    url: '/view/findHostAndNodeSum',
    params: {
      userId,
    },
  })
}
// 用户单位的 任务的安全状态 统计
export function findTaskSafeSum(userId) {
  return service({
    method: 'get',
    url: '/view/findTaskSafeSum',
    params: {
      userId,
    },
  })
}
// 用户单位的 任务的运行状态 统计
export function findTaskStateSum(userId) {
  return service({
    method: 'get',
    url: '/view/findTaskStateSum',
    params: {
      userId,
    },
  })
}

// 用户单位的 近4月报警 统计
export function findMonthAlarm(userId) {
  return service({
    method: 'get',
    url: '/view/findMonthAlarm',
    params: {
      userId,
    },
  })
}
