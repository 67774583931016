import service from '../utils/request'

/* 
  联系人 部分
*/
export function addPhone(phoneList) {
  return service({
    method: 'post',
    url: '/task/addPhone',
    data: phoneList,
  })
}

export function findPhone(id) {
  return service({
    method: 'get',
    url: '/task/findById',
    params: {
      id,
    },
  })
}

export function updatePhone(info) {
  return service({
    method: 'put',
    url: '/task/updatePhone',
    params: info,
  })
}

export function removePhone(id) {
  return service({
    method: 'delete',
    url: '/task/removePhone',
    params: { id },
  })
}

// 任务部分
export function addTask(taskInfo) {
  return service({
    method: 'post',
    url: '/task/addTask',
    data: taskInfo,
  })
}
// 查询主机下的任务
export function findTaskByHostId(name, id, page, pageSize) {
  return service({
    method: 'get',
    url: '/task/findTask',
    params: {
      name,
      id,
      page,
      pageSize,
    },
  })
}

// 查询任务详情
export function findTaskByTaskId(taskId) {
  return service({
    method: 'get',
    url: '/task/findTask',
    params: {
      taskId,
    },
  })
}

// 查询任务 的 所有 联系人
export function findTaskPhone(taskId) {
  return service({
    method: 'get',
    url: '/task/findPhone',
    params: {
      taskId,
    },
  })
}

// 修改任务(工程)信息
export function updateTask(taskInfo) {
  return service({
    method: 'put',
    url: '/task/updateTask',
    data: taskInfo,
  })
}

// 修改任务的状态
export function updateTaskState(taskId, stateId) {
  return service({
    method: 'put',
    url: '/task/updateTaskState',
    params: {
      taskId,
      stateId,
    },
  })
}

// 删除任务
export function removeTask(id) {
  return service({
    method: 'delete',
    url: '/task/removeTask',
    params: {
      id,
    },
  })
}

/* 
查询用户所有任务
*/
// 01 普通用户联系人
export function findAllTaskByUser(name, id, page, pageSize) {
  return service({
    method: 'get',
    url: '/task/findMany',
    params: {
      name,
      id,
      page,
      pageSize,
    },
  })
}
// 02.主机管理员
export function findAllTaskByHostUser(name, userId, page, pageSize) {
  return service({
    method: 'get',
    url: '/task/findTaskByUser',
    params: {
      name,
      userId,
      page,
      pageSize,
    },
  })
}
// 03.单位管理员 查询单位下的所有任务
export function findAllTaskByCompanyUser(name, companyId, page, pageSize) {
  return service({
    method: 'get',
    url: '/task/findTaskByUser',
    params: {
      name,
      companyId,
      page,
      pageSize,
    },
  })
}

// 04.超级管理员 查询所有任务
export function findAllTask(name, page, pageSize) {
  return service({
    method: 'get',
    url: '/task/findTask',
    params: {
      name,
      page,
      pageSize,
    },
  })
}

// 获取任务测点最新数据

export function findTaskNewData(type, taskId) {
  if (type.indexOf('V3') != -1) {
    return service({
      method: 'get',
      url: `/view/find${type[0]}New`,
      params: {
        taskId,
        versions: 3,
      },
    })
  } else {
    return service({
      method: 'get',
      url: `/view/find${type}New`,
      params: {
        taskId,
      },
    })
  }
}

// 查询任务开始时间 运行总时长

export function findTime(taskId) {
  return service({
    method: 'get',
    url: `/view/findTime`,
    params: {
      taskId,
    },
  })
}

export function removal(taskId) {
  return service({
    url: `/task/removal`,
    method: 'put',
    params: { taskId },
  })
}

export function findWeather(location) {
  return service({
    url: `/now?location=${location}&key=3714a671b10445f2929b59f1fcc2a573`,
    method: 'get',
  })
}

// 删除所有异常任务数据
export function clearAbnormalData(taskId) {
  return service({
    url: `/task/clearAbnormalData`,
    method: 'delete',
    params: { taskId },
  })
}
