import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/Layout/Home/index'
import Project from '@/Layout/Project/index'
import store from '../store'

import { findAllTaskByCompanyUser, findAllTaskByHostUser, findAllTaskByUser } from '@/api/Task.js'

import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(VueRouter)

const routes = [
  // 重定向
  {
    path: '/',
    component: Home,
    redirect: '/Login',
  },
  // home
  {
    path: '/Home',
    name: 'MapChart',
    component: Home,
    redirect: '/Home/MapChart',
    children: [
      {
        path: 'MapChart',
        name: 'MapChart',
        component: () => import(/* webpackChunkName: "MapChart" */ '@/views/MapChart/index.vue'),
        meta: {
          title: '地图预览',
          index: 0,
        },
      },
    ],
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login/index.vue'),
  },
  {
    path: '/Alarm',
    name: 'Alarm',
    component: Home,
    redirect: '/Alarm/Pending',
    children: [
      {
        path: 'Pending',
        name: 'AlarmPending',
        component: () => import(/* webpackChunkName: "AlarmPending" */ '@/views/Alarm/Pending/index.vue'),
        meta: {
          title: '待处理预警列表',
          index: 1,
        },
      },
      {
        path: 'Finish',
        name: 'AlarmFinish',
        component: () => import(/* webpackChunkName: "AlarmFinish" */ '@/views/Alarm/Finish/index.vue'),
        meta: {
          title: '已处理预警列表',
          index: 1,
        },
      },
    ],
  },
  {
    path: '/Project',
    name: 'project',
    component: Home,
    redirect: '/Project/List',
    children: [
      {
        path: 'List',
        name: 'projectList',
        component: () => import(/* webpackChunkName: "ProjectList" */ '@/views/ProjectList/index.vue'),
        meta: {
          title: '工程列表',
          index: 2,
        },
      },
    ],
  },
  {
    path: '/TaskManager',
    name: 'TaskManager',
    component: Home,
    redirect: '/TaskManager/Job',
    children: [
      {
        path: 'Job',
        name: 'TaskManagerJob',
        component: () => import(/* webpackChunkName: "TaskManagerJob" */ '@/views/TaskManager/index.vue'),
        meta: {
          title: '任务管理列表',
          index: 3,
        },
      },
    ],
  },
  {
    path: '/Docking',
    name: 'Docking',
    component: Home,
    redirect: '/Docking/List',
    children: [
      {
        path: 'List',
        name: 'DockingList',
        component: () => import(/* webpackChunkName: "DockingList" */ '@/views/Docking/index.vue'),
        meta: {
          title: '对接管理',
          index: 4,
        },
      },
    ],
  },
  {
    path: '/UnitManager',
    name: 'UnitManager',
    component: Home,
    redirect: '/UnitManager/User',
    children: [
      {
        path: 'User',
        name: 'UnitManagerUser',
        component: () => import(/* webpackChunkName: "UnitManagerUser" */ '@/views/UnitManager/User/index.vue'),
        meta: {
          title: '单位用户管理',
          index: 5,
        },
      },
      {
        path: 'Host',
        name: 'UnitManagerHost',
        component: () => import(/* webpackChunkName: "UnitManagerHost" */ '@/views/UnitManager/Host/index.vue'),
        meta: {
          title: '单位主机管理',
          padding: true,
          index: 6,
        },
      },
      {
        path: 'Node',
        name: 'UnitManagerNode',
        component: () => import(/* webpackChunkName: "UnitManagerNode" */ '@/views/UnitManager/Node/index.vue'),
        meta: {
          title: '节点使用情况',
          padding: true,
          index: 7,
        },
      },
    ],
  },
  {
    path: '/ProjectMonitor/:projectId',
    name: 'ProjectMonitor',
    component: Project,
    redirect: '/ProjectMonitor/:ProjectId/Condition',
    beforeEnter: (to, from, next) => {
      console.log(to, from)
      // 获取用户 ID
      const userId = store.state.userId
      const unitId = store.state.unitId
      const power = store.state.power
      console.log(userId, power)
      switch (power) {
        case 4:
          next()
          break
        case 3:
          findAllTaskByCompanyUser(null, unitId, 0, 9999).then((res) => {
            console.log(res)
            const jurisdiction = res.data.data.list.some((item) => {
              return item.pkTaskId === to.params.projectId - 0
            })
            if (jurisdiction) {
              next()
            } else {
              Message({
                message: '抱歉,您暂未拥有该工程访问权限!!!',
                type: 'error',
              })
              setTimeout(() => {
                router.go(-1)
              }, 200)
            }
          })

          break
        case 2:
          findAllTaskByHostUser(null, userId, 0, 9999).then((res) => {
            const jurisdiction = res.data.data.list.some((item) => {
              return item.pkTaskId === to.params.projectId - 0
            })
            if (jurisdiction) {
              next()
            } else {
              Message({
                message: '抱歉,您暂未拥有该工程访问权限!!!',
                type: 'error',
              })
              setTimeout(() => {
                router.go(-1)
              }, 200)
            }
          })
          break
        case 1:
          findAllTaskByUser(null, userId, 0, 9999).then((res) => {
            const jurisdiction = res.data.data.list.some((item) => {
              return item.pkTaskId === to.params.projectId - 0
            })
            if (jurisdiction) {
              next()
            } else {
              Message({
                message: '抱歉,您暂未拥有该工程访问权限!!!',
                type: 'error',
              })
              setTimeout(() => {
                router.go(-1)
              }, 200)
            }
          })
          break

        default:
          break
      }
    },
    children: [
      {
        path: 'Condition',
        name: 'PMCondition',
        component: () => import(/* webpackChunkName: "PMCondition" */ '@/views/ProjectMonitor/Condition/index.vue'),
      },
      {
        path: 'Data',
        name: 'PMData',
        component: () => import(/* webpackChunkName: "PMData" */ '@/views/ProjectMonitor/Data/index.vue'),
      },
      {
        path: 'Chart',
        name: 'PMCurves',
        component: () => import(/* webpackChunkName: "PMChart" */ '@/views/ProjectMonitor/Chart/index.vue'),
      },
      {
        path: 'Alarm',
        name: 'PMAlarm',
        component: () => import(/* webpackChunkName: "PMAlarm" */ '@/views/ProjectMonitor/Alarm/index.vue'),
      },

      {
        path: 'Video',
        name: 'PMVideo',
        component: () => import(/* webpackChunkName: "PMVideo" */ '@/views/ProjectMonitor/Video/index.vue'),
      },

      {
        path: 'Info',
        name: 'PMInfo',
        component: () => import(/* webpackChunkName: "PMInfo" */ '@/views/ProjectMonitor/ProjectInfo/index.vue'),
      },
      {
        path: 'PointConfig',
        name: 'PMPointConfig',
        component: () => import(/* webpackChunkName: "PMPointConfig" */ '@/views/ProjectMonitor/PointConfig/index.vue'),
      },
    ],
  },
  {
    path: '/StatisticalGraph/:projectId',
    name: 'StatisticalGraph',
    component: () => import(/* webpackChunkName: "StatisticalGraph" */ '@/views/StatisticalGraph/index.vue'),
  },
]

// 全局路由守卫

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  let logo = document.getElementById('logo')
  logo.href = '/public/favicon.ico'

  document.title = to.meta.title || '高支模在线预警云平台'

  // document.title = to.meta.title

  if (store.state.token) {
    if (to.path === '/Login') {
      router.push({ name: 'MapChart' })
    } else {
      next()
    }
  } else {
    if (to.path === '/Login') {
      next()
    } else {
      router.push({ name: 'Login' })
    }
  }
})

export default router
