<template>
  <div>
    <header>
      <span class="logo">
        <img :src="logo" />
      </span>

      <span style="margin-left: 60px; font-size: 20px; color: #fff"> 高支模在线监测预警云平台</span>
      <el-menu :default-active="defaultActive" router class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#141c39" text-color="#fff" active-text-color="#0DBC79">
        <el-menu-item index="/Home/MapChart"> 概况</el-menu-item>
        <el-menu-item index="/Project/List"> 工程列表</el-menu-item>

        <el-submenu index="/Alarm" v-permission="2">
          <template slot="title"> 报警列表 <el-badge class="mark" :value="pendingAlarm" v-show="pendingAlarm > 0" /></template>
          <el-menu-item index="/Alarm/Pending"> 待处理<el-badge class="mark" :value="pendingAlarm" v-show="pendingAlarm > 0" /></el-menu-item>
          <el-menu-item index="/Alarm/Finish"> 处理完成</el-menu-item>
        </el-submenu>
        <el-menu-item index="/TaskManager/Job" v-permission="2">工程管理</el-menu-item>
        <el-menu-item index="/Docking/List"> 对接列表</el-menu-item>
        <el-submenu index="/UnitManager">
          <template slot="title"> 单位资源管理</template>
          <el-menu-item v-permission="3" @click="dialogFormVisibleUpload = true"> 单位文件上传</el-menu-item>
          <el-menu-item index="/UnitManager/Host" v-permission="2"> 监测主机管理</el-menu-item>
          <el-menu-item index="/UnitManager/User" v-permission="3"> 单位用户管理</el-menu-item>
          <el-menu-item index="/UnitManager/Node"> 节点使用情况</el-menu-item>
        </el-submenu>

        <el-submenu index>
          <template slot="title"> 你好 {{ $store.state.name }}</template>
          <el-menu-item @click="dialogFormVisible = true"> 修改密码</el-menu-item>
          <el-menu-item @click="loginOut">退出登录 </el-menu-item>
        </el-submenu>
      </el-menu>
    </header>
    <el-dialog title="修改密码" center :visible.sync="dialogFormVisible" width="400px">
      <el-form>
        <el-form-item label="请输入旧密码" label-width="100px">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="请输入新密码" label-width="100px">
          <el-input v-model="form.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" label-width="100px">
          <el-input v-model="form.newPassword2"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="文件上传" center :visible.sync="dialogFormVisibleUpload" width="480px" class="upload">
      <el-form>
        <el-form-item label="请选择报告类型">
          <el-select v-model="upForm.fileForm" placeholder="请选择上传文件类型" @change="changeFileForm">
            <el-option v-for="item in list" :key="item.typeId" :label="item.name" :value="item.typeId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件" class="up">
          <el-upload class="upload-demo" :accept="accept" :file-list="fileList" :auto-upload="false" action="#" :limit="1" :on-change="getValChange" :on-exceed="handleExceed" list-type="picture" multiple drag>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip" v-if="!isPic">tips: 只能上传Pdf文件，且不超过40MB</div>
            <div class="el-upload__tip" slot="tip" v-else>tips: 只能上传jpg,png文件，且不超过10MB</div>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="uploadFileFun">立即上传</el-button>
          <el-button @click="$parent.handleClose()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// import md5 from 'js-md5'
import { updateUser } from '@/api/User.js'
import { uploadLogo } from '@/api/Uploading.js'
import { nthIndexOf } from '@/utils/nthIndexOf'

export default {
  data() {
    return {
      admin: false,
      defaultActive: this.$route.path,
      dialogFormVisible: false,
      dialogFormVisibleUpload: false,
      logo: this.$store.state.logo,
      list: [
        {
          name: 'logo',
          fileType: '.jpg,.png',
          type: 'logo',
          typeId: 1,
          isPic: true,
        },
      ],
      form: { password: null, newPassword: null, newPassword2: null },
      isPic: true,
      accept: '.jpg,.png',
      upForm: {
        fileForm: 1,
      },
      fileList: [],
      file: {},
      pendingAlarm: 0,
    }
  },
  watch: {
    $route() {
      this.defaultActive = this.handleSelect(window.location.pathname)
    },
  },
  methods: {
    handleSelect(value) {
      if (value) {
        const index = nthIndexOf(value, '/', 3)
        return index === -1 ? value : value.slice(0, index)
      }
    },
    loginOut() {
      this.$confirm('确定退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$store.commit('delUserInfo')
          this.$router.push({ name: 'Login' })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录',
          })
        })
    },
    async edit() {
      if (this.form.newPassword !== this.form.newPassword2) {
        this.$message({
          showClose: true,
          message: '两次输入密码不一致',
          type: 'error',
        })
        return
      }

      if (this.form.password === this.form.newPassword) {
        this.$message({
          showClose: true,
          message: '新旧密码一致！',
          type: 'warning',
        })
        return
      }

      console.log(this.form)

      const userInfo = {
        password: this.form.newPassword2.toString(),
        pkUserId: this.$store.state.userId,
      }

      console.log(userInfo)
      const { data: res } = await updateUser(userInfo)

      this.dialogFormVisible = false

      this.$message({
        showClose: true,
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },

    async uploadFileFun() {
      if (this.fileList[0].length === 0) {
        this.$message({
          message: '请上传文件',
          type: 'error',
        })
        return
      }

      if (this.upForm.fileForm === 1) {
        let formData = new FormData()
        formData.append('file', this.fileList[0])

        console.log(formData)
        const { data: res } = await uploadLogo(this.$store.state.unitId, formData)
        console.log(res)

        this.$store.commit('uploadLogo', res.data)

        this.$message({
          message: res.message,
          type: res.code === 200 ? 'success' : 'error',
        })

        this.handleClose()
      }
    },
    //   文件状态改变
    getValChange(file, fileList) {
      this.fileList = []
      if (fileList.length === 1) {
        this.fileList = [file.raw]
      }
    },
    // 超出文件数量限制
    handleExceed(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = []
        this.fileList = [file[0]]
      }
    },
    changeFileForm(e) {
      console.log(e)
      this.file = null
      this.fileList = []
      this.accept = this.list[e - 1].fileType
      this.isPic = this.list[e - 1].isPic
    },
    handleClose() {
      this.dialogFormVisible = false
      this.dialogFormVisibleUpload = false
    },
  },
  created() {
    console.log(this.$route)
    this.defaultActive = this.handleSelect(window.location.pathname)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
header {
  width: 100%;
  height: 60px;
  line-height: 60px;

  // background-color: #004c6e;
  background-color: #0a1631;
  background-color: #4045ab;
  background-color: #141c39;
  .logo {
    width: 60px;
    height: 60px;
    background-color: #0a1631;
    position: absolute;
    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  > .el-menu {
    border: none;
    background-color: #fff;
  }
  > .el-menu-demo.el-menu--horizontal.el-menu {
    // background-color: #fff;
    float: right;
  }
}
.iconfont {
  color: #fff;
}
.el-submenu.is-active .iconfont {
  color: #0dbc79;
}
.alarmTotal {
  color: #fc011a;
}
div {
  ::v-deep .el-dialog {
    height: 380px;
    .el-button {
      width: 160px;
    }
  }
  .upload ::v-deep .el-dialog {
    height: 640px;
  }
}

.el-badge {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  margin-top: -22px;
  ::v-deep .el-badge__content {
    // padding: 0 3px !important;
    padding: 0;
    width: 18px;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    border: none;
  }
  // font-size: 12px;
}
</style>
