<template>
  <div class="tagNave" ref="tagNave">
    <ul class="tagNave-tags">
      <li class="tagNave-tag" v-for="(item, index) in tagsList" :class="{ active: isActive(item.path) }" :style="isActive(item.path) ? backgroundColor : null" :key="index">
        <router-link :to="item.path" class="tagNave-tag-title">
          {{ item.title }}
        </router-link>
        <span class="tagNave-tag-close" @click="closeTags(index)"><i class="el-icon-close"></i></span>
      </li>
    </ul>
    <div class="tagNave-close">
      <el-dropdown @command="handleTags">
        <el-button size="mini" type="text"> 标签选项<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagsList: [],
    }
  },
  methods: {
    isActive(path) {
      return path === this.$route.fullPath
    },
    // 关闭单个标签
    closeTags(index) {
      if (this.tagsList.length > 1) {
        const delItem = this.tagsList.splice(index, 1)[0]
        const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1]
        delItem.path === this.$route.fullPath && this.$router.push(item.path)
      }
    },
    // 关闭全部标签
    closeAll() {
      if (this.tagsList.length <= 1) {
        return
      }

      this.tagsList = []
      this.$router.push('/')
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter((item) => {
        return item.path === this.$route.fullPath
      })
      this.tagsList = curItem
    },
    setTags(route) {
      // 判断当前标签是否存在
      const isExist = this.tagsList.some((item) => {
        return item.path === route.fullPath
      })
      if (!isExist) {
        if (this.tagsList.length > 9) {
          this.tagsList.shift()
        }
        this.tagsList.push({
          title: route.query.id !== undefined ? route.meta.title + route.query.id : route.meta.title,
          path: route.fullPath,
          name: route.meta.title,
        })
      }
    },
    handleTags(command) {
      command === 'other' ? this.closeOther() : this.closeAll()
    },
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0
    },
    theme: function () {
      return 'color: #24292f'
    },
    backgroundColor: function () {
      // 24292f
      return 'background-color: #141c39'
    },
  },
  watch: {
    $route(newVal) {
      console.log('监听', newVal)
      this.setTags(newVal)
    },
  },
  created() {
    this.setTags(this.$route)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.tagNave {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: 29px;
  border-bottom: 1px solid #dedede;
  overflow: hidden;
  padding-right: 120px;
  box-shadow: 0 2px 0px #f1f1f1;
  transition: left 0.3s ease;
  background-color: #ffffff;
  // background-color: #141c39;
  // background-color: #3f44a8;
}

.tagNave-tags {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tagNave-tag {
  float: left;
  margin: 2px 5px 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: #ccc;
  // color: #0fad58;
  transition: all 0.3s ease-in;
}

.tagNave-tag-title {
  float: left;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tagNave-tag.active .tagNave-tag-title {
  color: #fff;
  // color: #0fad58;
}

.tagNave-close {
  position: absolute;
  right: 20px;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 30px;
  background: #fff;
  z-index: 10;
}

.tagNave-close .el-button {
  color: #606266;
}
</style>
