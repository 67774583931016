// 用来全局设置axios请求 包含拦截器
import axios from 'axios'
axios.defaults.withCredentials = true
import { Message } from 'element-ui'

axios.defaults.withCredentials = true

import store from '../store/index'
import router from '../router/index'

const service = axios.create({
  baseURL: '/api/',
  timeout: 600000, //

  // headers: {
  //   'Content-Type': 'application/json;charset=UTF-8', //表单数据传递转化
  // },
})
// request 拦截器 发送数据到后台
service.interceptors.request.use(
  (config) => {
    if (store.state.token && !config.url.includes('login')) {
      config.headers.token = store.state.token || ''
    } else {
      config.headers.token = ''
    }
    return config
  },

  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

// response 拦截器 获取后台数据拦截
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 501) {
      store.commit('delUserInfo')
      Message({
        message: '用户登录已过期，请重新登录',
        type: 'error',
      })
      router.push({ name: 'Login' })
    }
    return response
  },
  (error) => {
    if (error.response.data.message === '用户登录已过期，请重新登录') {
      store.commit('delUserInfo')
      Message({
        message: '用户登录已过期，请重新登录',
        type: 'error',
      })
      router.push({ name: 'Login' })

      return Promise.reject(error.message)
    } else {
      Message({
        message: '网络错误',
        type: 'error',
      })
      return Promise.reject(error.message)
    }
  }
)

export default service

//   / api / xxx ===> /xxx
// /api/video/xxx ===> api
